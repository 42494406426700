import React from "react";
import "./experience.css";
import stremi from "../../assets/logo-stremi.png";
import iut from "../../assets/logo-iut.png";

const Experience = () => {
  return (
    <section id="experience">
      <h5>Mes</h5>
      <h2>Formations</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h4>Lycée Saint Rémi Roubaix</h4>
          <div className="experience__content">
            <small>Année 2017-2020</small>
            <br />
            <p>
              Baccalauréat scientifique spécialité Science de l'ingénieur Mention Bien.
              <br/>
              Première introduction à l'informatique, son fonctionnement, sa logique.
              <div className="experience__content_image">
                <img src={stremi} alt="about"></img>
              </div>
            </p>
          </div>
        </div>
        {/* End of frontend */}
        <div className="experience__backend">
          <h4>Institut Universitaire Technologique Lille</h4>
          <div className="experience__content">
            <small>Année 2021-2024 </small>
            <br />
            <p>
              Bachelor Universitaire de Technologie informatique. 
              <br/>
              Parcours informatique qui comprend réalisation d'applications : conception, développement, validation
              <div className="experience__content_image">
                <img src={iut} alt="about"></img>
              </div>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
