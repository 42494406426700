import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/header-img.svg'
import HeaderSocials from './HeaderSocials'
import Typical from 'react-typical'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h1>Hocine Aliouat</h1>
        <CTA/>
        <HeaderSocials/>
        <div className="me">
          <img src={ME} alt="me"></img>
        </div>
        <div className='header_writter'> 
        <h1>Je suis {' '}
        <Typical
          loop={Infinity}
          wrapper="b"
          steps={[
            'développeur java',
            1500,
            'développeur web',
            1500,
            'motivé',
            1500
          ]}
         />
         </h1>
        </div>
        <a href="#contact" className='scroll__down'>Suivez moi !</a>
      </div>
        
    </header>
  )
}

export default Header