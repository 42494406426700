import React from "react";
import "./portfolio.css";
import { useState } from "react";
import IMG1 from "../../assets/seriepb.png";
import IMG2 from "../../assets/classification.jpg";
import IMG3 from "../../assets/cvnum.jpg";
import IMG4 from "../../assets/modelisation.jpg";
import IMG5 from "../../assets/royalcrous.jpg";
import IMG6 from "../../assets/pizza.jpg";
import IMG7 from "../../assets/Bagario.JPG";
import vid from "../../assets/pre-cvnum.mkv";
import close from "../../assets/close.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Serie pas bête",
    git: "https://gitlab.univ-lille.fr/colin.blokkeel.etu/projet-2022",
    demo: "https://seriepasbete.hocinealiouat.com",
    summary: "Site de série utilisant une APi",
    skills: ["API", "JavaScript", "CSS", "Travail d'équipe", "Rigueur"],
  },
  {
    id: 2,
    image: IMG2,
    title: "Classification",
    git: "https://github.com",
    demo: "#portfolio",
    summary: "Projet de classification qui consiste à ranger des données dans le but de classifier une nouvelle donnée",
    skills: ["Java","Gestion de données","Méthodologique",'Adapatation'],
  },
  {
    id: 3,
    image: IMG3,
    title: "CV numérique",
    git: "https://github.com/Hocine59/react-portfolio",
    demo: "#portfolio",
    summary: "Mon CV numérique fait en Javascript à l'aide de React",
    skills: ["React", "JavaScript", "CSS", "Curiosité", "Autonomie"],
  },
  {
    id: 4,
    image: IMG4,
    title: "Modélisation",
    git: "https://github.com",
    demo: "#portfolio",
    summary: "Projet de modélisation 3D de fichier PLY en java",
    skills: ["Java",'Modèle 3D',"Organisation","Travail d'équipe"],
  },
  {
    id: 5,
    image: IMG5,
    title: "Royal Crous",
    git: "https://github.com",
    demo: "#portfolio",
    summary: "Jeu réalisé en 3 jours en appliquant les principes de l'agilité",
    skills: ["Agilité",'Imagination',"Organisation"]
  },
  {
    id: 6,
    image: IMG6,
    title: 'Serveur REST Pizza',
    git: 'https://github.com',
    demo: 'https://github.com',
    summary: "Le projet de serveur REST de pizza est un système de gestion de commandes de pizzas basé sur une architecture RESTful. Le serveur permet de stocker, récupérer et manipuler des données à distance via des requêtes HTTP, en utilisant les méthodes CRUD (Create, Read, Update, Delete).",
    skills: ["Structure REST",'API', "Logique", "Pérseverant"]
  },
  {
    id: 7,
    image: IMG7,
    title: 'bAgar Io',
    git: 'https://github.com',
    demo: 'https://github.com',
    summary: "Bagar Io est un jeu multijoueur en JavaScript réalisé en groupe de deux avec Socket.io. Dans ce jeu, les joueurs contrôlent des blobs dans une arène virtuelle et doivent manger les autres blobs pour grossir et devenir le plus gros blob de tous. Le jeu utilise une architecture client-serveur pour permettre une expérience de jeu en temps réel. Les joueurs peuvent se connecter à l'aide de leur navigateur et rejoindre une partie en cours avec d'autres joueurs. Le serveur gère la logique du jeu et les communications entre les joueurs.",
    skills: ["Socket.io","Javascript","Motivation","Curiosité"]
  },
];


const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleDetails = (id) => {
    const project = data.find((project) => project.id === id);
    setSelectedProject(project);
  };

  const handleCloseDetails = (id) => {
    if (selectedProject?.id === id) {
      setSelectedProject(null);
    } else {
      const project = data.find((project) => project.id === id);
      setSelectedProject(project);
    }
  };

  return (
    <section id="portfolio">
      <h5>Mes projets</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, git, demo, summary, skills }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item_cta">
                <a href={git} className="btn" target="_blank">
                  Git
                </a>
                <button className="btn btn-primary" onClick={() => handleCloseDetails(id)}>
                  Détails
                </button>
              </div>
              {selectedProject?.id === id && (
                <div className="portfolio__item-details">
                  <p>{summary}</p>
                  <ul>
                    {skills.map((skill, index) => (
                      <li key={index} className="skill-item">{skill}</li>
                    ))}
                  </ul>
                </div>
              )}
            </article>
          );
        })}
      </div>
    </section>
  );
};



export default Portfolio;